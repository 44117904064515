import React, { useState } from 'react'
import styles from "./NavBar.module.css"
import logo from "Assets/amortise-logo-1.svg"

const NavBar = (props) => {


    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    };



    return (
        <>
            <div className={`${styles.NavBar} ${open ? styles.active : null} `} >
                <div className={styles.nav1}>
                    <div className={styles.Logo} ><img src={logo} alt="amortise.co" /></div>
                    <div className={styles.Menu} onClick={toggleOpen}>
                        <div className={styles.bar1}></div>
                        <div className={styles.bar2}></div>
                        <div className={styles.bar3}></div>
                    </div>
                </div>
            </div>
            <div className={`${styles.NavItems} ${open ? styles.active : null} `} >
                <ul>
                    <li>Mortgage Illustration Calculator</li>
                    <li>Mortgage Comparison Calculator</li>
                </ul>
            </div>
            <div className={`${styles.NavSpace} ${open ? styles.active : null} `} ></div>

            <div className={`${styles.modal} ${open ? styles.active : null} `} onClick={toggleOpen}></div>
        </>
    )


}


export default NavBar
