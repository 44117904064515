import React  from 'react'
import MortCalc from './Container/MortCalc/MortCalc'
import { Route, Switch} from 'react-router-dom';


import  'globals.grids.css'

function App() {
  return (
    <div style={{background:'black', minHeight:"100vh"}}>
    <Switch>
         <Route path="/"  component={MortCalc} />
         </Switch>
    </div>
  );
}

export default App;
