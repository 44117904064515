import React, { Component } from 'react';
// import MortTable from 'Component/MortTable/MortTable';
import NavBar from 'Presentation/NavBar/NavBar'
import MortSummaryPanel from 'Presentation/MortSummaryPanel/MortSummaryPanel';
import MortInputPanel from 'Presentation/MortInputPanel/MortInputPanel';
import MortGraphPanel from 'Presentation/MortGraphPanel/MortGraphPanel';
import MortTable from 'Presentation/MortTable/MortTable';
// import { Input, Tabs, Tab, Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core/';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { Line } from 'react-chartjs-2';

import styles from './MortCalc.module.css'


class MortCalc extends Component {


    state = {
        m1: {
            rate: 1.59,
            principal: 450000,
            term: 25,
            intonly: false,
            payment: 0,   
            a_months: [],
            a_principal: [],
            a_interest: [],
            a_balance: [],
            c_payments: [],
            c_principal: [],
            c_interest: [],  
        },
        m2: {
            rate: 1.59,
            principal: 100000,
            term: 25,
            intonly: true,
            payment: 0, 
            a_months: [],
            a_principal: [],
            a_interest: [],
            a_balance: [],
            c_payments: [],
            c_principal: [],
            c_interest: [],    
        }
    }


    componentDidMount() {
        this.calcAmortisation('m1')
        this.calcAmortisation('m2')
    }

    


    inputChangeHandler = (v, n, m, mX) => {

       // console.log(v, n, m, mX)

        if(v==null) v=0
        
        const M = {}
        const MX = {}

        if(mX!=null) {         
            MX[m] = {...this.state[mX]}
            M[m] = {...this.state[m]}

                M[m].principal = MX[m].principal
                M[m].rate = MX[m].rate
                M[m].term = MX[m].term 
                M[m].intonly  = MX[m].intonly
        }
        else{
            M[m] = {
                ...this.state[m],
                [n]: v
            }
        }
        this.setState(M, () => { this.calcAmortisation(m) })
    }



    calcAmortisation = (m) => {

        const M = {...this.state[m]}
        const r = M.rate / 12 / 100;
        const P = M.principal;
        const n = M.term * 12;
        const io = M.intonly
  
        M.payment = !io ? Number(((r / (1 - (1 + r) ** -(n))) * P).toFixed(2)) : Number(P*r).toFixed(2)
        M.rate = Number(M.rate).toFixed(2)
        M.months = n

        for (var i = 0; i < n; i++) {

            M.a_months[i] = i
            M.a_balance[i] = i < 1 ? (P) : (M.a_balance[i - 1] - M.a_principal[i - 1]).toFixed(2)
            M.a_interest[i] = i < 1 ? (P * r).toFixed(2) : (M.a_balance[i] * r).toFixed(2)
            M.a_principal[i] = (M.payment - M.a_interest[i]).toFixed(2)

            M.c_payments[i] = ((i+1 ) * M.payment)
            M.c_interest[i] = i < 1 ? M.a_interest[i] : (Number(M.a_interest[i]) + Number(M.c_interest[i - 1])).toFixed(2)
            M.c_principal[i] = i < 1 ? M.a_principal[i] : (Number(M.a_principal[i]) + Number(M.c_principal[i - 1])).toFixed(2)

        }

        M.total_repayments = !io ? M.c_payments[n-1] : Number(M.c_payments[n-1]) + Number(P)
        M.total_interest = M.c_interest[n-1]

        this.setState({[m]: M})

       // console.log(this.state)
    }

    render() {



        return (
            <>

            <div className={styles.MortCalc} >
            <NavBar />
                <div className="panel_1">
                <MortInputPanel m1={this.state.m1} m2={this.state.m2} inputChangeHandler={this.inputChangeHandler} />
                <MortSummaryPanel m1={this.state.m1} m2={this.state.m2} />

                </div>
                <div className="panel_2">
                <MortGraphPanel m1={this.state.m1} m2={this.state.m2} /> 

                </div>
             
                 
                        
                        {/* <MortTable
                            a_balance={this.state.a_balance}
                            a_principal={this.state.a_principal}
                            c_principal={this.state.c_principal}
                            a_interest={this.state.a_interest}
                            c_interest={this.state.c_interest}
                        /> */}
               
                
            </div>
            </>
        )

    }

}

export default MortCalc