import React, { useState } from 'react'
import { defaults, Line } from 'react-chartjs-2';
import styles from './MortGraphPanel.module.css';



const MortGraphPanel = (props) => {

    defaults.global.animation = true;



    const m1_months = [...Array(props.m1.term * 12).keys()]
    const m2_months = [...Array(props.m2.term * 12).keys()]

    let M = { b1: [], b2: [], p1: [], p2: [], i1: [], i2: [] }


    for (const index of m1_months) {
        M.b1[index] = props.m1.a_balance[index]
        M.p1[index] = props.m1.c_principal[index]
        M.i1[index] = props.m1.c_interest[index]
    }

    for (const index of m2_months) {
        M.b2[index] = props.m2.a_balance[index]
        M.p2[index] = props.m2.c_principal[index]
        M.i2[index] = props.m2.c_interest[index]

    }


    const data = {
        labels: m1_months > m2_months ? m1_months : m2_months,
        datasets: [
            {
                label: 'M1: Balance ',
                data: M.b1,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'lightcoral',
                fill: false,
            },
            {
                label: 'M1: Principal',
                data: M.p1,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'orange',
                fill: false,

            },
            {
                label: 'M1: Interest',
                data: M.i1,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'gold',
                fill: false,

            },
            {
                label: 'M2: Balance ',
                data: M.b2,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'lightseagreen',
                fill: false,
            },
            {
                label: 'M2: Principal',
                data: M.p2,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'mediumaquamarine',
                fill: false,

            },
            {
                label: 'M2: Interest',
                data: M.i2,
                pointRadius: 0,
                borderWidth: 3,
                borderColor: 'paleturquoise',
                fill: false,

            }

        ],
    }
    const options = {
        scales: {
            xAxes: [{
                display: true,
                ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 10,
                },
                scaleLabel: {
                    // display: true,
                    // labelString: 'Months'

                },
            }],
            yAxes: [{
                display: true,
                ticks: {

                    maxTicksLimit: 8,
                    beginAtZero: true,
                    stepSize: 100000,
                },
                scaleLabel: {
                    // display: true,
                    // labelString: 'Amount (GBP)'

                },
            }],
            
        },
      
        layout: {
            padding: {
                left: 5,
                right:5,
                top:20,
                bottom:60,

            }
        },
        legend: {
            position: 'bottom',
            fullWidth: false,
            labels: {
                fontSize: 12,
                boxWidth: 35,
                padding: 20,
            },

        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
            duration: 0 // general animation time
        },
        hover: {
            animationDuration: 0 // duration of animations when hovering an item
        },
        responsiveAnimationDuration: 0 // animation duration after a resize
    }



    const [open, setOpen] = useState(true);

    const toggleOpen = () => {
        setOpen(!open);
    };


    return (


        <div className={`${styles.MortGraphPanel} ${open ? styles.open : null} `}>
            <div className="title" onClick={toggleOpen} >

                <h1>Comparison Graph</h1>
            </div>
            <div>
            </div>
            <Line data={data}  options={options} redraw />

        </div>
    )

}
export default MortGraphPanel